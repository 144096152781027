import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, take } from 'rxjs/operators';
import { inject } from '@angular/core';
import { environment } from '../../../../environments/enviroment';
import { MetaObject } from '@app/features/shared/shared-interfaces';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ApiRequestService {
  private readonly apiUrl = environment.apiUrl;
  lang: BehaviorSubject<string> = new BehaviorSubject(
    localStorage.getItem('lang') || 'ar',
  );

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) {
    this.translate.onLangChange.subscribe((lang) => {
      this.lang.next(lang.lang);
    });
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error.error || 'Server error');
  }

  private handleResponse<T>(response: T): T {
    return response;
  }

  public get<T>(endpoint: string, params?: any): Observable<T> {
    const lang = this.lang.asObservable();
    const get = this.http
      .get<T>(`${this.apiUrl}${endpoint}`, { params })
      .pipe(map(this.handleResponse), catchError(this.handleError));
    return lang.pipe(
      map((res) => {
        return res;
      }, take(1)),
      switchMap((res) => {
        return get;
      }),
    );
  }

  public post<T>(
    endpoint: string,
    body: any,
    headers?: HttpHeaders,
  ): Observable<T> {
    headers = headers ? headers.append("app-source", "unified-portal") :  new HttpHeaders().set('app-source', 'unified-portal');
    return this.http
      .post<T>(`${this.apiUrl}${endpoint}`, body, { headers })
      .pipe(map(this.handleResponse), catchError(this.handleError));
  }

  public put<T>(
    endpoint: string,
    body: any,
    headers?: HttpHeaders,
  ): Observable<T> {
    headers = headers ? headers.append("app-source", "unified-portal") :  new HttpHeaders().set('app-source', 'unified-portal');
    return this.http
      .put<T>(`${this.apiUrl}/${endpoint}`, body, { headers })
      .pipe(map(this.handleResponse), catchError(this.handleError));
  }

  public delete<T>(endpoint: string, params?: HttpParams): Observable<T> {
    return this.http
      .delete<T>(`${this.apiUrl}/${endpoint}`, { params })
      .pipe(map(this.handleResponse), catchError(this.handleError));
  }

  public setQueryParams(queries: MetaObject): HttpParams {
    let queryParams = new HttpParams();
    for (const query in queries) {
      if (queries[query] || queries[query] == 0) {
        if (Array.isArray(queries[query])) {
          queries[query].forEach((item: any, index: number) => {
            queryParams = queryParams.append(`${query}`, item);
          });
        } else {
          queryParams = queryParams.append(query, queries[query]);
        }
      }
    }
    return queryParams;
  }
}
